<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">结算管理</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">特殊优惠申请</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl" style="display: flex; flex-direction: column; align-items: flex-start">
          <div class="searchbox">
            <div title="班级编码" class="searchboxItem">
              <span class="itemLabel">班级编码:</span>
              <el-input v-model="pageData.projectCode" type="text" clearable size="small" placeholder="请输入班级编码" />
            </div>
            <div title="班级名称" class="searchboxItem">
              <span class="itemLabel">班级名称:</span>
              <el-input v-model="pageData.projectName" type="text" clearable size="small" placeholder="请输入班级名称" />
            </div>
            <div title="企业名称" class="searchboxItem">
              <span class="itemLabel">企业名称:</span>
              <el-input v-model="pageData.compName" type="text" clearable size="small" placeholder="请输入企业名称" />
            </div>
            <div title="结算单号" class="searchboxItem">
              <span class="itemLabel">结算单号:</span>
              <el-input v-model="pageData.billNo" type="text" clearable size="small" placeholder="请输入结算单号" />
            </div>
            <div>
              <el-button type="primary" class="bgc-bv" round @click="getData()">查询</el-button>
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
              style="width: 100%" :header-cell-style="tableHeader" stripe>
              <el-table-column label="序号" align="center" type="index" :index="indexMethod" width="100" />
              <el-table-column label="企业名称" align="left" prop="compName" show-overflow-tooltip width="200" />
              <el-table-column label="班级编码" align="left" prop="projectCode" show-overflow-tooltip width="200" />
              <el-table-column label="班级名称" align="left" prop="projectName" show-overflow-tooltip width="200" />
              <el-table-column label="区县" align="left" prop="areaName" show-overflow-tooltip width="200" />
              <el-table-column label="培训时间" align="left" prop="authorityNameEn" show-overflow-tooltip width="160">
                <template slot-scope="scope">
                  {{ scope.row.startDate }} - {{ scope.row.endDate }}
                </template>
              </el-table-column>
              <el-table-column label="入班人数" align="left" prop="projectJoinNum" show-overflow-tooltip width="100" />
              <el-table-column label="结业人数" align="left" prop="projectGraduationNum" show-overflow-tooltip
                width="100" />
              <el-table-column label="班级状态" align="left" prop="projectStudyState" show-overflow-tooltip width="100">

                <template slot-scope="scope">
                  {{ scope.row.auditState ? $setDictionary("PROJECTSTATE", scope.row.auditState) : '--' }}
                </template>
              </el-table-column>
              <el-table-column label="结算单号" align="left" prop="billNo" show-overflow-tooltip width="180" />
              <el-table-column label="应付金额" align="left" prop="totalFee" show-overflow-tooltip width="100" />
              <el-table-column label="结算时间" align="left" prop="billTime" show-overflow-tooltip width="160" />
              <el-table-column label="优惠后金额" align="left" prop="discountFee" show-overflow-tooltip width="100" />
              <el-table-column label="申请时间" align="left" prop="discountApplyTime" show-overflow-tooltip width="180">
                <template slot-scope="scope">
                  {{ scope.row.startDate }} - {{ scope.row.endDate }}
                </template>
              </el-table-column>
              <el-table-column label="审核状态" align="center" prop="auditState" show-overflow-tooltip width="120">

                <template slot-scope="scope">
                  {{ scope.row.auditState ? $setDictionary("BILL_PROJECT_DISCOUNT_STATE", scope.row.auditState) : '--'
                  }}
                </template>
              </el-table-column>
              <el-table-column label="审核备注" align="left" prop="auditRemark" show-overflow-tooltip width="200" />
              <el-table-column label="操作" align="center" width="100px" fixed="right">

                <template slot-scope="scope">
                  <el-button type="text" style="padding: 0px 5px" size="mini" @click="applyAndSeeOpen(scope.row)">{{
            scope.row.auditState == '10' || scope.row.auditState == '30' ? '查看' : '申请' }}</el-button>
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
    <!-- 弹框 - 申请&新增 -->
    <el-dialog :title="applyAndSeeData.auditState == '30' ? '优惠查看' : '优惠申请'" :visible.sync="applyAndSeeData.dialogVisible"
      width="700px" top="40px" @close="applyAndSeeDataClose">
      <el-form ref="applyAndSeeData" label-width="8.75rem" label-position="right" class="applyAndSeeData"
        :rules="applyAndSeeDataRules" :model="applyAndSeeData">
        <el-form-item label="班级名称:">
          {{ applyAndSeeData.projectName }}
        </el-form-item>
        <el-form-item label="企业名称:">
          {{ applyAndSeeData.compName }}
        </el-form-item>
        <el-form-item label="培训时间:">
          {{ applyAndSeeData.startDate }} - {{ applyAndSeeData.endDate }}
        </el-form-item>
        <el-form-item label="培训人数:">
          {{ applyAndSeeData.projectJoinNum }}
        </el-form-item>
        <el-form-item label="企业应付金额:">
          {{ applyAndSeeData.totalFee }}
        </el-form-item>
        <el-form-item label="申请优惠后金额:" prop="discountFee">
          <el-input-number placeholder="请输入申请优惠后金额" v-model="applyAndSeeData.discountFee" :precision="2" :min="0"
            size="small">
          </el-input-number>
        </el-form-item>
        <el-form-item label="申请理由:" prop="applyReason">
          <el-input type="textarea" placeholder="请输入申请理由" v-model="applyAndSeeData.applyReason" maxlength="200"
            show-word-limit>
          </el-input>
        </el-form-item>
        <el-form-item label="上传附件:" class="uploadFile">
          <el-upload class="upload-demo upload-btn" :on-change="uploadChange"
            :file-list="applyAndSeeData.applyAnnexList" :show-file-list="false" :auto-upload="false">
            <el-button class="bgc-bv" size="mini">上传附件</el-button>
          </el-upload>
          <div class="applyAnnexList" v-for="(item, index) in applyAndSeeData.applyAnnexList" :key="index">
            <span class="applyAnnexList_1">
              {{ item.fileName }}
            </span>
            <span class="applyAnnexList_2" @click="lookExl(item)">
              预览
            </span>
            <span class="applyAnnexList_3" @click="removeExl(item, index)">
              删除
            </span>
          </div>
        </el-form-item>
      </el-form>
      <!-- 没申请过 或 驳回的能操作 -->
      <span slot="footer" class="dialog-footer"
        v-if="[undefined, '20'].includes(applyAndSeeData.auditState)">
        <el-button @click="applyAndSeeDataClose" class="bgc-bv">取 消</el-button>
        <el-button @click="applyAndSeeDataDetermine" class="bgc-bv">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 弹框 - 预览附件 -->
    <el-dialog title="预览" :visible.sync="seeFileData.dialogVisible" @close="seeFileDataClose" width="700px" top="30px"
      center>
      <div class="ovy-a">
        <div id="pdf-cert1" style="height: 600px" v-if="seeFileData.fileType == 'pdf' || seeFileData.fileType == 'PDF'">
        </div>
        <div v-else>
          <img :src="seeFileData.fileUrl" alt="" width="100%" height="100%" />
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import pdf from "pdfobject";
export default {
  name: "financialSettlement_settlement_specialDiscounts",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      // 页面数据
      pageData: {
        projectCode: "", // 班级编码
        projectName: "", // 班级名称
        compName: "", // 企业名称
        billNo: "", // 结算单号
      },
      // 申请&新增弹框数据
      applyAndSeeData: {
        dialogVisible: false, // 弹框状态
        discountFee: "", // 申请优惠后金额
        applyReason: "", // 申请理由
        applyAnnexList: [], // 上传附件列表
      },
      // 申请&新增弹框数据校验
      applyAndSeeDataRules: {
        discountFee: [
          { required: true, message: "请输入申请优惠后金额", trigger: "blur" },
        ],
        applyReason: [
          { required: true, message: "请输入申请理由", trigger: "blur" },
        ],
      },
      // 预览附件弹框数据
      seeFileData: {
        dialogVisible: false, // 弹框状态
        fileType: "", // 预览文件类型
        fileUrl: "", // 文件地址
      },
    };
  },
  computed: {

  },
  mounted() {

  },
  watch: {},
  methods: {
    // 获取列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize
      };
      if (this.pageData.projectCode) {
        params.projectCode = this.pageData.projectCode
      }
      if (this.pageData.projectName) {
        params.projectName = this.pageData.projectName
      }
      if (this.pageData.compName) {
        params.compName = this.pageData.compName
      }
      if (this.pageData.billNo) {
        params.billNo = this.pageData.billNo
      }
      this.doFetch({
        url: "/biz/bill/project/discount/pageList",
        params,
        pageNum,
      });
    },
    // 申请&查看 - 弹框 - 打开
    applyAndSeeOpen(row) {
      // 申请过 有申请id的掉详情（详情接口反的是可输入的那些字段）
      if (row.discountId) {
        this.$post('/biz/bill/project/discount/detail', {
          discountId: row.discountId
        }).then(res => {
          this.applyAndSeeData = { ...this.applyAndSeeData, ...row, ...res.data }
        });
        // 申请
      } else {
        this.applyAndSeeData = { ...this.applyAndSeeData, ...row }
      }
      this.applyAndSeeData.dialogVisible = true;
    },
    // 申请&查看 - 弹框 - 上传附件
    uploadChange(file, fileList) {
      let _this = this;
      let size = file.size / 1024 / 1024;
      let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isJpg = extension === "jpg";
      const isJPG = extension === "JPG";
      const isPng = extension === "png";
      const isPNG = extension === "PNG";
      const isPDF = extension === "PDF";
      const isPdf = extension === "pdf";
      if (!isJpg && !isJPG && !isPng && !isPNG && !isPDF && !isPdf) {
        _this.$message.error("只能上传后缀是.jpg或.png或者.pdf的文件");
        return;
      }
      if (size > 3) {
        _this.$message.error("文件大小不能超过3M");
        return;
      }
      let formData = new FormData();
      formData.append("folder ", "QUESTION");
      formData.append("file ", file.raw);
      formData.append("fileType ", extension);
      _this
        .$Postformat("/sys/upload", formData)
        .then((result) => {
          _this.applyAndSeeData.applyAnnexList.push({
            fileName: file.name,
            fileKey: result.data.fileKey,
            fileUrl: result.data.fileURL,
            fileType: extension,
          });
        })
        .catch(() => { })
    },
    // 申请&查看 - 弹框 - 上传附件 - 预览
    lookExl(item) {
      this.seeFileData.dialogVisible = true;
      this.seeFileData.fileType = item.fileType;
      if (item.fileType == "pdf" || item.fileType == "PDF") {
        this.$nextTick(() => {
          pdf.embed(item.fileUrl, "#pdf-cert1");
        });
      } else {
        this.seeFileData.fileUrl = item.fileUrl;
      }
    },
    // 申请&查看 - 弹框 - 上传附件 - 预览 - 关闭
    seeFileDataClose() {
      // 数据重置
      this.seeFileData = this.$options.data().seeFileData;
    },
    // 申请&查看 - 弹框 - 上传附件 - 删除
    removeExl(item, index) {
      this.applyAndSeeData.applyAnnexList.splice(index, 1);
    },
    // 申请&查看 - 弹框 - 确定
    applyAndSeeDataDetermine() {
      this.$refs['applyAndSeeData'].validate((valid) => {
        if (valid) {
          if (this.applyAndSeeData.discountFee >= this.applyAndSeeData.totalFee) {
            this.$message({
              type: "warning",
              message: "申请优惠后金额不能大于企业应付金额",
            });
            return
          }
          let params = {
            billProjectId: this.applyAndSeeData.billProjectId,
            discountFee: this.applyAndSeeData.discountFee,
            applyReason: this.applyAndSeeData.applyReason,
            applyAnnexList: this.applyAndSeeData.applyAnnexList
          }
          let url = "";
          if(this.applyAndSeeData.auditState == '20'){
            url = '/biz/bill/project/discount/update';
            params.discountId = this.applyAndSeeData.discountId;
          } else {
            url = '/biz/bill/project/discount/apply';
          }
          this.$post(url, params).then(res => {
            if (res.status == "0") {
              this.$message({
                type: "success",
                message: "操作成功",
              });
              this.applyAndSeeDataClose();
              this.getData(this.pageNum);
            }
          });
        }
      })
    },
    // 申请&查看 - 弹框 - 关闭
    applyAndSeeDataClose() {
      // 数据重置
      this.applyAndSeeData = this.$options.data().applyAndSeeData;
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .uploadFile .upload-btn {
  margin-top: 6px !important;
}

/deep/ .uploadFile .upload-btn .el-upload {
  height: auto;
  border: none;
}

.applyAndSeeData {
  .el-form-item {
    margin-bottom: 10px;
  }

  .uploadFile {
    margin-top: 20px;

    .applyAnnexList {
      display: flex;
      height: 30px;
      line-height: 30px;

      .applyAnnexList_1 {
        width: 400px;
        line-height: 30px;
      }

      .applyAnnexList_2 {
        color: #409eff;
        margin: 0 10px;
        cursor: pointer;
      }

      .applyAnnexList_3 {
        color: #F56C6C;
        margin: 0 10px;
        cursor: pointer;
      }
    }
  }
}
</style>
